import React from 'react';
import PropTypes from 'prop-types';

const TagIcon = ({ type }) => <mark className={`tag tag-icon ${type}`}>&nbsp;</mark>;

TagIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TagIcon;
