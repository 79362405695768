import React from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';

import NextChevron from '../../../../public/static/icons/navigation-chevron-right-white.svg';
import NextChevronThick from '../../../../public/static/icons/navigation-chevron-right-white-thick.svg';

import styles from './index.module.scss';

const LinkChevron = ({
  nextLink = null,
  label,
  size = 'normal',
  direction = 'right',
  isBold = false,
}) => (
  <Link {...nextLink}>
    <a
      className={`link-chevron ${styles['link-chevron']} ${styles[`size-${size}`]} ${
        styles[direction]
      } ${isBold ? styles.bold : ''}`}
    >
      {direction === 'left' && (isBold ? <NextChevronThick /> : <NextChevron />)}

      {isBold ? <strong>{label}</strong> : label}

      {direction === 'right' && (isBold ? <NextChevronThick /> : <NextChevron />)}
    </a>
  </Link>
);

LinkChevron.propTypes = {
  nextLink: PropTypes.shape(linkPropTypes),
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'normal']),
  direction: PropTypes.oneOf(['left', 'right']),
  isBold: PropTypes.bool,
};

export default LinkChevron;
