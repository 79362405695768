import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import ItemWithImage from '../../../02_molecules/Item/ItemWithImage';
import Slider from '../../../01_atoms/Slider';
import { LinkChevron } from '../../../02_molecules/Link';
import { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

class BBRelatedContent extends React.Component {
  sliderSettings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToScroll: 1,
    afterChange: this.onAfterSlideChange.bind(this),
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSlideIndex: 0,
    };
  }

  onAfterSlideChange(index) {
    this.setState({ activeSlideIndex: index });
  }

  render() {
    const { title, items, link, button, background, isMobileCollapsed, behaviorSettings, uuid } =
      this.props;
    const { activeSlideIndex } = this.state;

    const classes = [
      'bb',
      'bb-related-content',
      `bg-color-${background}`,
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];
    return (
      <div
        className={classes.join(' ')}
        id={uuid}
        // Hide BBSidebarTableOfContents when it is nearby the element.
        data-toc="hide"
      >
        <div className="container">
          <div className="heading">
            {title && <h2 className="rockitt">{title}</h2>}

            {link && link.label && link.nextLink && (
              <LinkChevron nextLink={link.nextLink} label={link.label} />
            )}
          </div>

          <div className={`slider ${isMobileCollapsed ? 'd-md-none' : 'd-none'}`}>
            <Slider {...this.sliderSettings}>
              {items.map((item) => (
                <ItemWithImage key={item.title} className="bb-related-content--link" {...item} />
              ))}
            </Slider>
            <p className="counter">
              <strong>
                {activeSlideIndex + 1}/{items.length}
              </strong>
            </p>
          </div>

          <div className={`row not-collapsed ${isMobileCollapsed ? 'd-none d-md-flex' : ''}`}>
            {items.map((item) => (
              <div className="col-xs-12 col-md-4" key={item.title}>
                <ItemWithImage className="bb-related-content--link" {...item} />
              </div>
            ))}
          </div>

          {button && button.label && button.nextLink && (
            <div className="text-center">
              <Link {...button.nextLink}>
                <Button
                  href={button.nextLink.url}
                  type="secondary"
                  tag="a"
                  isOutlined
                  isMobileBlock
                  className="bb-related-content--button"
                >
                  {button.label}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BBRelatedContent.propTypes = {
  title: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  background: PropTypes.oneOf(['white', 'biscuit']),
  isMobileCollapsed: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape(picturePropTypes),
      nextLink: PropTypes.shape(linkPropTypes),
      type: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      timestamp: PropTypes.number,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBRelatedContent.defaultProps = {
  title: '',
  link: null,
  button: null,
  background: 'white',
  isMobileCollapsed: true /* Make slider on mobile for items by default */,
  behaviorSettings: null,
  uuid: null,
};

export default BBRelatedContent;
