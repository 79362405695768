import React from 'react';
import Link from '../Link';

import { PTBreadcrumbs } from './prop-types.js';

import styles from './index.module.scss';

const Breadcrumbs = ({ links, color = 'turquoise', showOnMobile = false }) => (
  <div className={`${styles.breadcrumbs} ${color} ${showOnMobile ? '' : 'd-none d-md-block'}`}>
    {links.map((link, i) =>
      i + 1 === links.length && !link.nextLink ? (
        link.label
      ) : (
        <React.Fragment
          key={`${link.label}-${i}` /* eslint-disable-line react/no-array-index-key */}
        >
          <Link {...link.nextLink}>
            <a title="" href={link.nextLink.url || link.nextLink.href}>
              {link.label}
            </a>
          </Link>
          <span>/</span>
        </React.Fragment>
      ),
    )}
  </div>
);

Breadcrumbs.propTypes = PTBreadcrumbs.isRequired;

export default Breadcrumbs;
